.elocal-call-a-pro-root {
  .signup-form-container {
    background: url("https://s3.amazonaws.com/callapro.com/hero_bgImg.png") no-repeat;
    background-size: cover;
    min-height: calc(100vh - 102px - 250px);
    display: flex;
    justify-content: center;
    // @media(max-width: 480px) {
    // 	padding: 0;
    // }
    .signup-form-wrapper {
      border-radius: 8px;
      width: 100%;
      padding: 40px 20px;
      margin: 0 auto;
      max-width: 900px;
      // @media(max-width: 480px) {
      // 	max-width: none;
      // 	align-items: center;
      // 	margin: 0;
      // 	border-radius: 0;
      // }
      > p {
        text-align: center;
      }
    }
    h2 {
      padding: 20px 0 15px;
      @media (max-width: 480px) {
        text-align: center;
      }
    }
    h3 {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 20px;
      @media (max-width: 480px) {
        font-size: 16px;
      }
    }
    fieldset {
      // border: none;
      // width: 100%;
      padding: 0 0 20px 0;
      margin: 0 auto;
      .row {
        // display: flex;
        margin: 0 -5px 6px;
        width: auto;
        align-items: normal;
        @media (max-width: 480px) {
          flex-direction: column;
          margin: 0 !important;
          gap: 0;
        }
        &.phone-container {
          max-width: none;
          .input-container {
            margin: 0 auto 10px !important;
          }
        }
        .input-container {
          width: calc(100% - 10px);
          margin: 0 5px 10px !important;
          // @media(max-width: 480px) {
          // 	width: 100%;
          // 	margin: 0 0 5px !important;
          // }
          input {
            font-size: 16px;
          }
          &.mini {
            flex: 3;
          }
          &.micro {
            flex: 1;
            max-width: calc(25% - 10px);
            // @media(max-width: 480px) {
            // 	max-width: 100%;
            // }
          }
          &.error {
            .error_msg {
              display: block;
              text-align: left;
            }
          }
          // svg {
          // 	opacity: .5;
          // }
          &.phone {
            flex: 1 1;
            svg {
              top: calc(50% - 8px);
            }
          }
          &.email {
            flex: 2 1;
            input {
              padding-left: 36px;
            }
            svg {
              top: 15px;
            }
          }
          &.radio-container {
            flex-direction: row;
            flex-wrap: wrap;
            @media (max-width: 768px) {
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: flex-start;
            }
            .button {
              flex: 1 0 calc(50% - 10px);
              @media (max-width: 786px) {
                flex: 1 0 100%;
              }
              // @media(max-width: 768px) {
              // 	flex: 1;
              // }
              &.radio {
                font-size: 16px;
                font-weight: 300 !important;
                padding-left: 60px;
              }
            }
          }
          &.disclaimer-container {
            position: relative;
            padding-left: 30px;
            // border-top: 1px solid rgba(0, 0, 0, .15);
            padding-top: 20px;
            margin: 30px 5px !important;
            &.error {
              label,
              a {
                color: #ff4446;
              }
            }
            input[type='checkbox'] {
              height: 20px;
              width: 20px;
              position: absolute;
              top: 22px;
              left: 0;
            }
            label {
              font-size: 12px;
              line-height: 1.4;
              display: inline-block;
              cursor: pointer;
            }
            a {
              text-decoration: underline;
            }
          }
        }
        .error_msg {
          display: none;
          text-align: left;
          padding: 3px 0 3px 10px;
          font-size: 13px;
        }
      }
    }
    .bid-prices-table {
      table {
        border: 1px solid rgba(0, 0, 0, 0.25);
        margin: 20px 0;
        min-width: 400px;
        th {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          background: rgba(0, 0, 0, 0.1);
        }
        th,
        td {
          padding: 4px 20px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
      .loading {
        text-align: center;
        font-size: 20px;
        margin: 40px 0;
      }
    }
    .number-of-calls-estimate-container {
      margin-bottom: 5px;
      strong {
        font-size: 40px;
        display: inline-block;
        margin-left: 5px;
        color: #ff4446;
      }
    }
    .estimate-note {
      font-size: 12px;
      font-style: italic;
      margin-bottom: 40px;
    }
    .summary-container {
      display: flex;
      flex-wrap: wrap;
      gap: 50px;
      > * {
        flex: 1 0 calc(50% - 50px);
        &:hover,
        &:focus,
        &:active {
          .edit-trigger {
            color: #ff4446;
          }
        }
        h2 {
          font-size: 24px;
          // margin-bottom: 10px;
          @media (max-width: 480px) {
            text-align: left;
          }
        }
        .edit-trigger {
          font-size: 12px;
          font-weight: 400;
          color: transparent;
          font-family: var(--main-font);
          // @media(max-width: 768px) {
          // 	color: #ff4446;
          // }
        }
        ul,
        ol {
          list-style: none;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
