.elocal-call-a-pro-root {
  .sms-lead-form-container,
  .lead-flow-form-container {
    background: url('https://s3.amazonaws.com/callapro.com/hero_bgImg.png') no-repeat;
    background-size: cover;
    min-height: calc(100vh - 102px);
    display: flex;
    justify-content: center;
    .sms-form-wrapper,
    .lead-flow-wrapper {
      border-radius: 8px;
      width: 100%;
      padding: 40px 20px;
      margin: 0 auto;
      max-width: 900px;
      // @media(max-width: 480px) {
      // 	max-width: none;
      // 	align-items: center;
      // 	margin: 0
      // }
      .suggestions {
        margin-bottom: 30px;
        margin-left: 4px;
      }
      .headline-bullets {
        list-style: none;
        text-align: center;
        line-height: 32px;
        font-size: 22px;
        margin: 4px 0 25px;
        @media (max-width: 480px) {
          font-size: 16px;
          line-height: 1.2em;
          text-align: left;
          padding: 0;
          margin-bottom: 14px;
        }
      }
    }
    h1 {
      font-size: 36px;
      line-height: 47.7px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 20px;
      @media (max-width: 480px) {
        font-size: 22px;
        line-height: 29.15px;
      }
      &.has-bullets {
        margin-bottom: 0;
      }
    }
    h2 {
      font-size: 20px;
      line-height: 32px;
      text-align: center;
      font-weight: 400;
      font-family: var(--main-font);
      margin-bottom: 20px;
      width: 100%;
      max-width: 455px;
      margin: 0 auto 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #d0d0d0;
    }
    .thanks,
    .server-error {
      margin: 0 auto;
      max-width: 700px;
      h2 {
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        padding-bottom: 20px;
      }
      .content-container {
        padding: 20px;
        p {
          margin: 0 30px 14px;
          font-weight: 200;
          line-height: 1.3;
          // @media(max-width: 480px) {
          // 	margin: 0 0 14px;
          // }
        }
      }
      a {
        white-space: nowrap;
        text-underline-offset: 3px;
      }
      .icon-container {
        height: 136px;
        width: 136px;
        margin: 0 auto 40px;
        svg {
          height: 136px;
          width: auto;
        }
      }
      .content-container {
        margin: 80px 0;
        font-size: 20px;
        a {
          color: #ff4446;
          text-decoration: underline;
        }
      }
    }
    .actions-container {
      margin-top: 10px;
      text-align: right;
      p {
        text-align: center;
      }
    }
    h3 {
      font-size: 22px;
      font-weight: 700;
    }
    .image-container {
      padding: 10px 0;
      img {
        max-width: 250px;
        max-height: 200px;
      }
    }
    .address {
      font-size: 13px;
    }
    .desktop {
      @media (max-width: 480px) {
        display: none;
      }
    }
    .mobile {
      display: none;
      @media (max-width: 480px) {
        display: block;
      }
    }
    &.custom {
      .mobile-wrap {
        .zip_code {
          flex: 1;
        }
        .phone {
          flex: 2;
        }
      }
    }
  }
  fieldset {
    font-family: var(--main-font);
    border: none;
    padding: 0;
    text-align: center;
    width: 100%;
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 3px;
      margin-bottom: 10px;
      padding-bottom: 10px;
      &.next-container {
        margin: 10px 0 0;
        display: flex;
        justify-content: center;
        .actions {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        .next {
          min-width: 455px;
          @media (max-width: 480px) {
            flex: 1 0 100%;
            // width: calc(100% - 10px);
            min-width: 0;
            margin: 0 auto;
          }
        }
      }
      &.submit-container {
        margin: 10px 0;
        display: flex;
        justify-content: center;
        .next {
          min-width: 160px;
        }
      }
      &.budget-container,
      &.phone-container {
        display: block !important;
        max-width: 200px;
        margin: 0 auto 40px !important;
      }
      &.budget-container {
        margin: 0 0 40px !important;
      }
      &.mobile-wrap {
        @media (max-width: 480px) {
          display: block;
          .input-container {
            margin-bottom: 20px !important;
            input {
              width: 100%;
            }
          }
        }
      }
      .input-container {
        flex: 2;
        &.hasValue {
          input,
          textarea,
          select {
            font-weight: 400;
          }
        }
        &.radio-container {
          margin: 20px 0;
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          @media (max-width: 480px) {
            gap: 0;
          }
          > * {
            width: calc(50% - 10px);
            display: flex;
            justify-content: flex-start;
            padding-left: 60px;
            text-align: left;
            @media (max-width: 480px) {
              width: 100%;
              justify-content: flex-start;
              margin: 0;
              padding-left: 40px;
            }
          }
        }
        input,
        textarea,
        select {
          border: 1px solid #d0d0d0;
          padding: 20px 30px;
          height: var(--fixed-height);
          &:active,
          &:focus {
            border-color: #ff4446;
            box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
          }
        }
        textarea {
          height: 120px;
        }
        select {
          appearance: none;
          background: #fff
            url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
            no-repeat 98% 50%;
        }
        &.zip_code,
        &.phone,
        &.email,
        &.business_phone,
        &.currency,
        &.budget {
          position: relative;
          flex: none;
          margin: 0 auto;
          width: auto;
          // @media(max-width: 768px) {
          // 	flex: 1;
          // }
          svg {
            position: absolute;
            top: 17px;
            left: 8px;
            height: 20px;
            width: 20px;
            opacity: 1;
            path {
              fill: #333;
            }
          }
          input {
            padding-left: 32px;
          }
        }
        &.phone {
          input {
            padding-left: 40px;
          }
          svg {
            top: calc(50% - 13px);
            height: 26px;
            width: auto;
          }
        }
        &.email {
          input {
            padding-left: 40px;
          }
          svg {
            height: 26px;
            width: auto;
            top: calc(50% - 13px) !important;
          }
        }
        &.location,
        &.contact {
          width: 100%;
          max-width: 455px;
          input {
            padding-left: 40px;
          }
          svg {
            height: 26px;
            width: auto;
            top: calc(50% - 13px) !important;
          }
        }
        &.budget {
          // max-width: 300px;
          svg {
            height: auto;
            // width: 20px;
            // opacity: 1 !important;
            top: calc(50% - 10px);
          }
          input {
            font-size: 24px !important;
            font-weight: 500;
            // border-color: transparent;
            margin: 0 auto;
            // box-shadow: none;
            // padding-left: 60px;
            // text-align: center;
          }
        }
        &.email {
          flex: 3 1;
          &.full_width {
            width: 100%;
          }
        }
        &.zip_code {
          @media (max-width: 768px) {
            flex: none;
          }
          @media (max-width: 480px) {
            flex: 1;
          }
          svg {
            top: calc(50% - 13px);
            height: 26px;
            width: auto;
          }
        }
        &.actions {
          flex: 1;
        }
        &.error {
          input,
          textarea,
          select {
            border-color: #ff4446;
            color: #ff4446;
            background-color: #faf7af;
          }
          .error_msg {
            display: block;
            text-align: center;
          }
        }
      }
    }
    .suggestions {
      font-size: 15px;
      color: #979797;
      margin: 3px 0;
    }
    .error_msg {
      color: #ff4446;
      font-weight: 400;
      padding: 3px 0;
      font-size: 16px;
      text-align: center;
      ul {
        font-size: 20px;
        list-style: none;
        font-weight: 500;
      }
    }
    .search {
      padding: 0 30px;
      margin-left: 12px;
      svg {
        height: 25px;
      }
    }
    .email-phone-toggle {
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: #15668f;
      cursor: pointer;
      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
    }
    .options-panel {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 80%;
      margin: 30px auto;
      gap: 40px;
      @media (max-width: 320px) {
        gap: 20px;
      }
      .option-panel-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        padding: 20px;
        transition: all 0.25s ease;
        box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        @media (max-width: 320px) {
          padding: 10px;
        }
        &:hover {
          border-color: #ff4446;
          transform: scale(1.05);
          svg {
            path {
              fill: #ff4446;
            }
          }
          label {
            color: #ff4446;
          }
        }
        svg {
          width: 80px;
          height: auto;
          @media (max-width: 320px) {
            width: 60px;
          }
          path {
            transition: all 0.25s ease;
            fill: rgba(0, 0, 0, 0.65);
          }
        }
        label {
          @media (max-width: 320px) {
            font-size: 12px;
          }
        }
      }
    }
    .checkbox-container {
      position: relative;
      margin: 0 0 30px;
      @media (min-width: 769px) {
        width: 75%;
        margin: 0 auto 25px;
      }
      &.full_width {
        width: 100%;
      }
      &.ivr {
        width: 100%;
        max-width: 455px;
        label {
          font-size: 12px;
          line-height: 20px;
        }
        a {
          text-decoration: underline;
          font-weight: 700;
        }
      }
      input[type='checkbox'] {
        position: absolute;
        top: 2px;
        left: 0px;
        height: 12px;
        width: 12px;
        @media (max-width: 480px) {
          padding: 0;
        }
      }
      label {
        display: block;
        font-size: 12px;
        padding-left: 18px;
        margin: 0;
        font-weight: 500;
        color: #7b8286;
        cursor: pointer;
        @media (max-width: 480px) {
          margin-top: 10px;
        }
        a {
          font-weight: 500;
        }
      }
      .error_msg {
        display: none;
        text-align: left;
        padding-top: 3px;
      }
      &.error {
        input {
          border-color: #ff4446;
          color: #ff4446;
        }
        label {
          color: var(--red-darker);
          font-weight: 500;
        }
        .error_msg {
          display: block;
        }
      }
    }
  }

  .error-message {
    color: var(--red-darker);
    font-size: 14px;
  }
  .error {
    input,
    textarea,
    select {
      border-color: var(--red-darker);
      color: var(--red-darker);
      background-color: #faf7af;
      label {
        color: var(--red-darker);
      }
    }
  }

  .circle-loader {
    top: -24px;
    left: 50%;
    border-radius: 50%;
    display: block;
    width: 10px;
    height: 10px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 -0.16s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
    color: #ffffff;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    animation-delay: -0.16s;
    transform: translateZ(0);
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation: load7 1.8s infinite ease-in-out;
      animation: load7 1.8s infinite ease-in-out;
    }
    &:before {
      left: -15px;
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
    &:after {
      left: 15px;
    }
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
}
