@use 'sass:color';
@import '@/styles/theme';

.summit-wrapper {
  --fixed-width: 200px;
  --fixed-height: 50px;
  --main-font: var(--font-barlow);
  --primary-soft: #ff696a;
  --accent-background: #eef1f4;
  --action: #fa6854;
  --dark-grey: #525f6f;
  --error: #f23a52;
  --font: #252b31;
  --med-grey: #2f363b;
  --secondary: #1dc584;
  --dark-secondary: #027b4c;
  --red-darker: #b73e18;

  display: flex;
  padding: 0 15px 75px;
  flex-wrap: wrap;
  -webkit-font-smoothing: antialiased !important;
  @include screen-size(lg) {
    flex-direction: column;
    padding: 0;
    align-items: center;
  }
  .rail {
    width: calc(100% - 660px);
    padding-right: 30px;
    position: relative;
    @include screen-size(lg) {
      width: 100%;
      padding: 10px 15px 30px;
    }
    label {
      font-size: 15px;
      line-height: 1.5em;
      font-weight: 700;
      color: var(--dark-secondary);
      margin-bottom: 5px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
  .main {
    width: 660px;
    @include screen-size(lg) {
      width: 100%;
    }
  }
}
.summit-form-wrapper {
  font-family: var(--font-merriweather);
  margin: 0 0 30px;
  input {
    padding: 3px 35px 3px 15px;
    font-size: 16px;
    border: 1px solid #c1cad2;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    display: block;
    font-family: var(--font-merriweather);
    font-weight: 400;
    &:hover,
    &:focus,
    &:active {
      border-color: var(--secondary);
    }
  }
  .submit {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 17px 15px;
    position: relative;
    text-align: center;
    background-color: var(--action);
    color: #fff;
    border-radius: 3px;
    font-size: 26px;
    font-family: var(--font-merriweather);
    line-height: 1.2em;
    font-weight: 700;
    border: 0;
    width: 100%;
    cursor: pointer;
    transition:
      box-shadow 0.2s ease-in-out,
      transform 0.2s ease-in-out;
    &:hover,
    &:focus,
    &:active {
      transform: translateY(-2px);
      box-shadow: 0 10px 10px -5px rgb(0 0 0 / 25%);
    }
    &[disabled] {
      cursor: not-allowed;
      &:hover,
      &:focus,
      &:active {
        cursor: not-allowed;
      }
    }
    svg {
      height: 24px;
      path {
        fill: #fff;
      }
    }
  }
  .button {
    font-size: 20px;
    border: 2px solid transparent;
    border-radius: 3px;
    line-height: 44px;
    height: 50px;
    padding: 0 40px;
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
    background: #fff;
    transition: all 0.25s ease;
    box-shadow: 0 3px 12px rgb(0 0 0 / 20%);
    @include screen-size(lg) {
      font-size: 16px;
    }
    svg {
      height: 30px;
      width: auto;
      display: inline-block;
    }
    &:hover,
    &:focus,
    &:active {
      background: #de5a18;
      color: #fff;
      text-decoration: none;
    }
    &.secondary {
      background: #15668f;
      color: #fff;
      &:hover,
      &:focus,
      &:active {
        color: #fff;
        text-decoration: none;
        background: color.adjust(#15668f, $lightness: -10%);
      }
    }
    &.radio {
      position: relative;
      height: auto;
      line-height: 1.2em;
      border: none;
      box-shadow: none;
      font-size: 14px;
      color: var(--med-grey);
      background: transparent;
      font-family: var(--font-merriweather);
      &::before {
        content: '';
        height: 16px;
        width: 16px;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - 10px);
        left: 0;
        border: 2px solid rgb(0 0 0 / 35%);
        transition: all 0.25s ease;
      }
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        background: transparent;
      }
      &.selected {
        cursor: default;
        &::before {
          display: none;
        }
      }
      svg {
        position: absolute;
        height: 20px;
        width: 20px;
        top: calc(50% - 10px);
        left: 0;
        path {
          fill: var(--secondary);
        }
      }
    }
    &.radio-thumb {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;
      font-size: 13px;
      font-family: var(--font-merriweather);
      color: var(--med-grey);
      height: auto;
      width: 70px;
      line-height: 1.2em;
      padding: 10px 0;
      cursor: pointer;
      img {
        width: 46px;
        height: 46px;
      }
      .icon-container {
        display: none;
        height: 30px;
        align-items: center;
        justify-content: center;
        svg {
          height: 30px;
          path {
            fill: var(--secondary);
          }
        }
      }
      &:hover,
      &:focus,
      &:active {
        background: transparent;
      }
      &.selected {
        cursor: default;
        border-color: var(--secondary);
        color: var(--secondary);
        img {
          display: none;
        }
        .icon-container {
          display: flex;
        }
      }
    }
  }
}
