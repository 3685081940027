@mixin screen-size($size) {
  @if $size == xs {
    @media (max-width: 375px) {
      @content;
    }
  } @else if $size == sm {
    @media (max-width: 480px) {
      @content;
    }
  } @else if $size == md {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $size == lg {
    @media (max-width: 1000px) {
      @content;
    }
  }
}
