:root {
  --fixed-height: 72px;

  /** named colors **/
  --red: #ff4446;
  --red-darker: #b73e18;
  --off-black: #323232;
  --font-color: #2e2e2e;
  --alt-grey: #474747;
  --yellow: rgb(254 223 55);
  --yellow-darker: rgb(249 210 1);
}
* {
  box-sizing: border-box;
  margin: 0;
  outline: none;
}
html,
body {
  padding: 0;
  margin: 0;
  color: #333;
}
body {
  &.modal {
    overflow: hidden;
    > .overlay {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1000;
    }
  }
}
main {
  --main-font: var(--font-inter);
  --heading-font: var(--font-pt_serif);
  font-family: var(--main-font);
}
::placeholder {
  opacity: 0.8;
}
.page-wrapper {
  /** theme colors */
  --logo-primary: #000;
  --logo-secondary: var(--red);

  max-width: 1728px;
  margin: 0 auto;
  @media (max-width: 768px), (max-width: 834px) and (-webkit-min-device-pixel-ratio: 2) {
    padding-top: 70px;
  }
}
h1 {
  font-family: var(--heading-font);
  font-style: normal;
  font-weight: 700;
  font-size: 68px;
  line-height: 1.2em;
  -webkit-font-smoothing: antialiased;
  @media (max-width: 480px) {
    font-size: 32px;
    line-height: 40px;
  }
}
h2 {
  font-family: var(--heading-font);
  font-size: 40px;
  // margin: 10px 0 20px;
  font-weight: 700;
  @media (max-width: 480px) {
    font-size: 20px;
  }
}
h3 {
  font-size: 28px;
  // font-weight: 500;
  // line-height: 1.6;
  @media (max-width: 480px) {
    // font-size: 15px;
  }
}
.desktop {
  @media (max-width: 480px) {
    display: none;
  }
}
.mobile {
  display: none;
  @media (max-width: 480px) {
    display: block;
  }
}
a,
button {
  transition: all 0.25s ease;
  color: #000;
  font-weight: 600;
  text-decoration: none;
  word-wrap: break-word;
  text-underline-offset: 10px;
}
a:hover,
a:focus,
a:active {
  color: #ff4446;
  text-decoration: none;
}
a:focus-visible {
  outline: 2px solid #ff4446;
}
input,
textarea,
select {
  background-color: #fff;
  font-family: var(--main-font);
  padding: 20px 30px;
  font-size: 18px;
  line-height: 32px;
  height: var(--fixed-height);
  width: 100%;
  display: block;
  font-weight: 400;
  border: 1px solid #d0d0d0;
  transition: all 0.25s ease;
  &:hover {
    border-color: #b9b9b9;
  }
  &:active,
  &:focus {
    border-color: #ff4446;
  }
  &:focus-visible {
    outline: 1px solid #ff4446;
  }
  @media (max-width: 480px) {
    // display: block;
    // // margin: 5px 0 0;
    // font-size: 16px;
  }
}
select {
  appearance: none;
  background: #fff
    url("data:image/svg+xml,%3Csvg width='19' height='12' viewBox='0 0 19 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.5 11.5L18.1603 0.25H0.839746L9.5 11.5Z' fill='black'/%3E%3C/svg%3E")
    no-repeat 98% 50%;
}
// textarea {
//   padding: 8px;
//   height: 100px;
// }
// .submit {
//   height: 50px;
//   border: 1px solid #ff4446;
//   border-radius: 3px;
//   padding: 0 12px;
//   background: #ff4446;
//   color: #fff;
//   font-weight: 400;
//   font-size: 16px;
//   box-shadow: 0 6px 20px -15px rgba(0, 0, 0, .2);
//   white-space: nowrap;
//   cursor: pointer;
//   &:hover, &:focus, &:active {
//     background: #f47231;
//     border-color: #ff4446;
//   }
//   &[disabled] {
//     cursor: not-allowed;
//     &:hover, &:focus, &:active {
//       cursor: not-allowed;
//     }
//   }
// }
.container {
  width: 100%;
  max-width: 1218px;
  display: block;
  margin: 0 auto;
  padding: 0 20px;
}
.dynamic-form-container {
  min-height: 100svh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.full-page-form-container {
  padding: 25px;
}
.panels-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px 0;
}
.panel {
  flex: 1;
  flex-basis: 10%;
  padding: 20px;
  margin: 10px;
  font-size: 14px;
  background: #fff;
  text-align: center;
  border: 1px solid rgba(200, 200, 200, 0.5);
  transition: all 0.25s ease;
  @media (max-width: 480px) {
    font-size: 15px;
  }
  img,
  svg {
    height: 80px;
    width: 80px;
    padding: 8px;
    display: block;
    margin: 0 auto;
    cursor: pointer;
  }
  label {
    cursor: pointer;
    line-height: 1.2em;
    display: block;
    font-weight: 500;
    margin-top: 8px;
  }
}
.price {
  font-size: 36px;
  font-weight: 600;
  font-family: 'Anton', sans-serif;
  margin-bottom: 12px;
  color: #fff;
  letter-spacing: 1.2px;
}
.rail-container {
  display: flex;
  justify-content: space-between;
  &.inverse {
    .main-content {
      padding-right: 30px;
      padding-left: 0;
      @media (max-width: 768px) {
        padding-right: 0;
      }
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .main-content {
    flex: 5;
    padding-left: 30px;
    @media (max-width: 768px) {
      padding-left: 0;
    }
    h2 {
      text-align: left;
    }
    img {
      max-width: 100%;
      border-radius: 5px;
    }
  }
  .rail {
    flex: 2;
    &.large {
      flex: 3;
    }
    @media (max-width: 768px) {
      order: 2;
    }
    .rail-panel {
      padding: 20px 0;
      border: none;
      border-bottom: 1px solid #f3f3f3;
      border-radius: 0;
      margin-right: 10px;
      @media (max-width: 768px) {
        text-align: center;
      }
      h2 {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.5px;
        color: v#1c2f39;
        padding-bottom: 4px;
      }
      p {
        font-weight: 300;
        font-size: 14px;
        .highlight {
          color: #b73e18;
          font-weight: 700;
        }
      }
      .cta-container {
        margin: 8px 0;
      }
    }
  }
}
.button {
  font-family: var(--main-font);
  font-size: 20px;
  line-height: 24.2px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  padding: 0 32px;
  transition: all 0.25s ease;
  height: var(--fixed-height);
  background: #eb0004;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border: 1px solid #eb0004;
  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 4px 20px 0 rgba(255 68 70 / 35%);
    color: #fff;
  }
  &.small {
    --fixed-height: 50px;

    font-size: 18px;
    line-height: 20px;
    padding: 10px 15px;
  }
  &.large {
    height: 75px;
    padding: 20px 48px;
    font-size: 22px;
    line-height: 31.47px;
    font-weight: 700;
    white-space: nowrap;
    .icon-container {
      height: 26px;
      width: auto;
      svg {
        height: 26px;
        width: auto;
        path {
          fill: #fff;
        }
      }
    }
  }
  &.alt {
    background: transparent;
    color: #ff4446;
    &:hover,
    &:active,
    &:focus {
      background: #fff1f1;
      box-shadow: none;
    }
    .icon-container {
      svg {
        path {
          fill: #ff4446;
        }
      }
    }
  }
  &.round {
    border-radius: 5px;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
    }
  }
  &.radio {
    position: relative;
    background: #fff;
    color: #000;
    border-color: #d0d0d0;
    @media (max-width: 480px) {
      font-weight: 400;
      font-size: 18px;
      border-right: none;
      border-left: none;
      border-top: none;
      .caret {
        display: none;
        color: #45494e;
        svg {
          left: auto;
          right: 10px;
          fill: #45494e !important;
        }
      }
    }
    &:before {
      content: '';
      height: 26px;
      width: 26px;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 13px);
      left: 10px;
      border: 1px solid #ff4446;
      transition: all 0.25s ease;
      @media (max-width: 480px) {
        // display: none;
        height: 18px;
        width: 18px;
        top: calc(50% - 9px);
      }
    }
    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
      text-decoration: none;
      // &:before {
      //   border-color: #15668f;
      // }
    }
    &.selected {
      box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
      @media (max-width: 480px) {
        box-shadow: none;
      }
      svg {
        fill: #ff4446;
        @media (max-width: 480px) {
          // display: none;
          height: 20px;
          width: 20px;
        }
      }
      cursor: default;
      &:before {
        display: none;
      }
      .caret {
        svg {
          display: block;
        }
      }
    }
    svg {
      position: absolute;
      height: 26px;
      width: 26px;
      top: calc(50% - 13px);
      left: 13px;
      @media (max-width: 480px) {
        height: 20px;
        width: 20px;
        top: calc(50% - 10px);
        left: 10px;
      }
    }
  }
  // &.mini {
  //   font-size: 15px;
  //   line-height: 26px;
  //   height: 30px;
  //   padding: 0 16px;
  //   svg {
  //     height: 12px;
  //     width: 12px;
  //     position: relative;
  //     top: 1px;
  //     margin-right: 8px;
  //   }
  // }
  // svg {
  //   height: 30px;
  //   width: auto;
  //   display: inline-block;
  //   path {
  //     fill: #fff;
  //   }
  // }
}
mark {
  background: #efdca4;
  color: v#1c2f39;
}
// .highlight {
//   color: #B73E18;
//   font-weight: 700;
// }
.sticky {
  position: sticky;
  @media (max-width: 768px) {
    position: static;
  }
}
