.abandon-flow-modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  .modal-container {
    background: #fff;
    width: 40vw;
    height: 40vh;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.3);
    @media (max-width: 768px) {
      width: 80vw;
      height: 80vh;
    }
    h2 {
      text-align: center;
      font-size: 28px;
      padding: 20px 10px;
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
    .body {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      @media (max-width: 768px) {
        height: calc(100% - 74px - 80px);
      }
      p {
        font-size: 18px;
      }
      .content-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 30px;
      }
    }
    .close-trigger {
      font-weight: 400;
      font-size: 13px;
      text-decoration: underline;
      transition: all 0.25s ease;
      margin-top: 20px;
      &:hover,
      &:focus,
      &:active {
        color: #ff4446;
        opacity: 1;
      }
    }
  }
}
